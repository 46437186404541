<template>
	<div class="scroll" id="top" :style="{ top: `${top}px`, bottom: `${bottom}px` }" @scroll="scroll">
		<!-- 下拉区域 -->
		<!--  -->
		<!-- 内容区域 -->
		<slot></slot>
		<!-- 触底加载区 -->
		<div class="lazyloading" v-if="lazyloading === true">
			<div class="circle" v-if="status === 'loading'"></div>
			<div>{{ loadText[status] }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'scrollView',
	props: {
		top: {
			type: Number,
			default: 0
		},
		bottom: {
			type: Number,
			default: 0
		},
		lazyloading: {
			type: Boolean,
			default: false
		},
		status: {
			type: String,
			default: 'loadmore'
		}
	},
	data() {
		return {
			loadText: {
				loadmore: '上拉加载更多...',
				loading: '加载中...',
				nomore: '没有更多了...'
			}
		};
	},
	mounted() {},
	methods: {
		scroll(e) {
			// 滚动中
			this.$emit('onScroll', e);
			let dom = document.getElementById('top');
			if (dom.scrollTop + dom.clientHeight >= dom.scrollHeight) {
				// 滚到底部
				this.$emit('scroll-bottom');
			}
		}
	}
};
</script>

<style scoped>
.scroll {
	position: fixed;
	left: 0;
	right: 0;
	background: #f5f5f5;
	z-index: 99;
	overflow-x: hidden;
	overflow-y: scroll;
	/* background: #006edc; */
}
.circle {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	box-sizing: border-box;
	margin-right: 6px;
	border: 1px solid #006edc;
	border-bottom-color: #ffffff;
	animation: zhuan 0.5s linear;
	animation-iteration-count: infinite;
}

@keyframes zhuan {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.lazyloading {
	width: 100%;
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	position: relative;
	/* margin-bottom: -50px; */
	color: #999999;
	/* background: #006EDC; */
}
</style>
