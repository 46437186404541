<template>
	<div id="containers"></div>
</template>

<script>
import echarts from './echarts.min.js';
export default {
	props: {
		chartData: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {};
	},
	watch: {
		chartData(list) {
			this.shows(list);
		}
	},
	methods: {
		shows(data) {
			let dom = document.getElementById('containers');
			let myChart = echarts.init(dom);
			myChart.setOption({
				tooltip: {
					trigger: 'item.value',
					formatter: '{}%'
				},
				legend: {
					bottom: 'auto'
				},
				series: [
					{
						name: 'Nightingale Chart',
						type: 'pie',
						radius: [50, 80],
						center: ['50%', '50%'],
						itemStyle: {
							borderRadius: 0,
							normal: {
								color: params => {
									return data[params.dataIndex].color;
								}
							}
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '14',
								fontWeight: 'bold'
							}
						},
						data
					}
				]
			});
		}
	}
};
</script>

<style scoped></style>
