<template>
	<div class="content">
		<NavBar :fixed="true" @click-left="onClickLeft" left-arrow title="详情"></NavBar>

		<!--  -->

		<div class="top-box" :style="{ height: `${getStatus() ? 96 : 123}px` }" v-if="list.length > 0">
			<div class="title">以下正在申请的商标与您的注册商标存在近似风险！如需帮助，请联系顾问</div>
			<div class="right-arrow" @click="onSelect">
				<div>初审公告期:</div>
				<div class="rightTxt">{{ selectOptions[pickerKey] }}</div>
				<img src="https://zhisheng-ws.oss-cn-hangzhou.aliyuncs.com/2490780464062.png" />
			</div>

			<div class="bei" v-if="!getStatus() && endTime > 0">
				<span>距离初审公告期结束还有</span>
				<span style="color: #006EDC;">{{ endTime }}天</span>
			</div>
			<div class="bei" v-if="!getStatus() && endTime <= 0">
				<span>初审公告期已结束</span>
				<span style="color: #006EDC;">{{ Math.abs(endTime) }}天</span>
			</div>
		</div>

		<scrollView :lazyloading="true" :status="status" :top="list.length == 0 ? 46 : 162 + (getStatus() ? 0 : 27)" :bottom="96" @scroll-bottom="onMoreData">
			<!--  -->
			<div class="list-cell" v-for="(item, index) in list" :key="index">
				<img class="img" :src="item.imageUrl" />
				<div class="span1 van-ellipsis">{{ item.brandName }}</div>

				<div class="span3">
					<div>第{{ item.int_cls }}类</div>
				</div>

				<div class="span5">
					<div>{{ item.tm_applicant }}</div>
				</div>
				<div class="span4">
					<div>
						该商标因
						<span style="color: #006EDC;">{{ item.types }}</span>
						侵权预警您已认证的
						<span style="color: #006EDC;">{{ item.myCom }}</span>
						持有的
						<span style="color: #006EDC;">{{ item.related.brands }}</span>
						（第{{ item.related.intCls }}类）商标
					</div>
				</div>
			</div>

			<van-empty v-if="list.length == 0" class="empty" image="search" description="暂无任何数据" />
		</scrollView>
		<van-popup position="bottom" v-model="show"><van-picker show-toolbar :columns="selectOptions" @confirm="onConfirm" @cancel="onCancel" @change="onChange" /></van-popup>
		<!-- 滚动 -->
		<consult></consult>
	</div>
</template>

<script>
import { NavBar } from 'vant';
import { getCompanySelect } from '@/api/api';
import consult from '../../components/consult.vue';
import scrollView from '../../components/scrollView.vue';
import moment from './moment/dist/moment.js';

// 其他更多是格式化有如下:
// yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合
const timeFormat = function(dateTime = null, fmt = 'yyyy-mm-dd') {
	// 如果为null,则格式化当前时间
	if (!dateTime) dateTime = Number(new Date());
	// 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
	if (dateTime.toString().length == 10) dateTime *= 1000;
	let date = new Date(dateTime);
	let ret;
	let opt = {
		'y+': date.getFullYear().toString(), // 年
		'm+': (date.getMonth() + 1).toString(), // 月
		'd+': date.getDate().toString(), // 日
		'h+': date.getHours().toString(), // 时
		'M+': date.getMinutes().toString(), // 分
		's+': date.getSeconds().toString() // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (let k in opt) {
		ret = new RegExp('(' + k + ')').exec(fmt);
		if (ret) {
			fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
		}
	}
	return fmt;
};
export default {
	components: {
		NavBar,
		consult,
		scrollView
	},
	data() {
		return {
			list: [],
			com: '',
			page: 1,
			size: 10,
			loading: false,
			finished: true,
			status: 'loadmore',
			show: false,
			timestamp: '',
			selectOptions: [],
			pickerKey: 0,
			day: 0
		};
	},
	mounted() {
		this.com = this.$route.query.com;
		this.getData();
	},
	watch: {
		pickerKey() {
			this.page = 1;
			this.list = [];
			this.status = 'loadmore';
			this.$nextTick(() => {
				this.getData();
			});
		}
	},
	computed: {
		endTime() {
			if (this.pickerKey || this.pickerKey === 0) {
				let a = moment(this.selectOptions[this.pickerKey]).add(3, 'M');
				let b = moment();
				return a.diff(b, 'days');
			}
			return '';
		}
	},
	methods: {
		getStatus() {
			return this.selectOptions[this.pickerKey] === '全部' ? true : false;
		},
		onConfirm(value, index) {
			this.show = false;
			this.pickerKey = index;
		},
		onSelect() {
			this.show = true;
		},
		onChange() {},
		onCancel() {
			this.show = false;
		},
		onMoreData() {
			if (this.status === 'loadmore') {
				this.status = 'loading';
				this.getData();
			}
		},
		onClickLeft() {
			this.$router.back();
		},
		getData() {
			try {
				let data = {
					comName: this.com,
					timestamp: this.getStatus() ? '' : this.selectOptions[this.pickerKey],
					index: this.page,
					size: this.size
				};
				getCompanySelect(data).then(res => {
					try {
						// console.log(res);

						let list;
						let dateList;

						try {
							list = res.dataList || [];
							dateList = res.dateList || [];
						} catch (e) {
							list = [];
							dateList = [];
						}

						if (this.selectOptions.length === 0) {
							dateList = dateList.sort((a, b) => a.key - b.key);
							this.selectOptions = dateList.map(i => {
								return timeFormat(i.key);
							});
							this.selectOptions.unshift('全部');
						}

						// console.log(this.selectOptions);

						if (list.length !== 0) {
							this.list = this.list.concat(list);
						}

						if (list.length > 0) this.page = this.page + 1;

						this.$nextTick(() => {
							this.status = list.length === 0 ? 'nomore' : 'loadmore';
						});
					} catch (e) {
						//TODO handle the exception
						// Toast(e.message);
						this.status = 'loadmore';
					}
				});
			} catch (e) {
				//TODO handle the exception
				// Toast(e.message);
				this.status = 'loadmore';
			}
		}
	}
};
</script>

<style scoped>
body {
	background: #f5f5f5;
	width: 100%;
	height: 100%;
}
.content {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-y: hidden;
	background: #f5f5f5;
}

.empty {
	height: calc(100vh);
	width: 100%;
}

.right-arrow {
	position: absolute;
	overflow: hidden;
	top: 59px;
	left: 14px;
	right: 17px;
	height: 17px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-weight: 400;
	color: #000000;
	line-height: 17px;
	font-size: 12px;
}

.right-arrow > img {
	width: 10px;
	height: 10px;
	position: absolute;
	right: 0;
}

.titile {
	position: absolute;
	top: 16px;
	left: 16px;
	height: auto;
	width: calc(100% - 32px);
	font-size: 16px;
	font-weight: 500;
	color: #000000;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 22px;
	overflow: hidden;
	background: #ee6666;
}
.num-box {
	position: absolute;
	top: 50px;
	left: 16px;
	height: auto;
	width: calc(100% - 32px);
	font-size: 16px;
	font-weight: 500;
	color: #000000;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 22px;
	overflow: hidden;
}

.top-box {
	width: calc(100% - 20px);
	background: #ffffff;
	border-radius: 8px;
	margin: auto;
	position: fixed;
	overflow: hidden;
	top: 56px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
}
.title {
	color: #f96006;
	font-size: 12px;
	font-weight: 400;
	position: absolute;
	top: 14px;
	left: 14px;
	right: 14px;
	width: auto;
	height: 35px;
	overflow: hidden;
	line-height: 17px;
}

.rightTxt {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 15px;
}
.list-cell {
	width: calc(100% - 20px);
	margin-left: 10px;
	height: 191px;
	min-height: 191px;
	max-height: 191px;
	background: #ffffff;
	position: relative;
	overflow: hidden;
	opacity: 1;
	margin-top: 10px;
	border-radius: 8px;
}

.list-cell:active {
	opacity: 0.8;
}

.list-cell::after {
	content: '';
	height: 1px;
	background: #f5f5f5;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}
.img {
	position: absolute;
	width: 90px;
	height: 90px;
	left: 14px;
	top: 16px;
	border-radius: 8px;
}
.span1 {
	position: absolute;
	top: 16px;
	left: 119px;
	font-weight: 400;
	font-size: 14px;
	color: #333333;
	height: 20px;
}

.bei {
	position: absolute;
	left: 14px;
	bottom: 20px;
	font-size: 12px;
}

.span3 {
	position: absolute;
	top: 46px;
	left: 119px;
	font-weight: 400;
	font-size: 15px;
	color: #000000;
	opacity: 0.6;
	right: 0;
	width: 100%;
	height: 21px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.span4 {
	top: 116px;
	width: 199px;
	left: 119px;
	height: 54px;
	position: absolute;
	font-weight: 400;
	font-size: 12px;
	color: #000000;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	word-wrap: break-word;
	overflow: hidden;
}

.span5 {
	position: absolute;
	top: 85px;
	left: 119px;
	font-weight: 500;
	font-size: 14px;
	color: #000000;
	opacity: 0.6;
	right: 0;
	width: 100%;
	height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
</style>
