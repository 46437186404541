<template>
	<div class="content">
		<NavBar :fixed="true" @click-left="onClickLeft" left-arrow title="搜索"></NavBar>
		<!-- search -->
		<div class="top-search">
			<div class="center-gray">
				<div class="reletive">
					<img class="search-icon" src="../../assets/search.png" />
					<input :autofocus="focus" type="text" v-model="value" class="input-box" placeholder="请输入企业/公司名称" />
					<img @click="onClear" v-if="value != ''" class="delet-box" src="../../assets/delet.png" />
					<div class="btn-sure" @click="onSelect(value)">确认</div>
				</div>
			</div>
		</div>

		<!-- cell -->
		<div class="card-box" v-if="list.length > 0">
			<div class="cell" @click="onSelect(item)" v-for="(item, index) in list" :key="index">{{ item }}</div>
		</div>

		<van-empty class="empty" v-else image="search" description="暂无任何数据" />
	</div>
</template>

<script>
import { NavBar, Toast } from 'vant';
import { getComFuzzy } from '@/api/api';
export default {
	name: 'searchCompany',
	components: {
		NavBar
	},
	data() {
		return {
			value: '',
			list: [],
			focus: false
		};
	},
	watch: {
		value(nld) {
			this.getData(nld);
		}
	},
	mounted() {
		this.$nextTick(() => (this.focus = true));
	},
	methods: {
		onClear() {
			this.value = '';
		},
		onClickLeft() {
			this.$router.back();
		},
		onSelect(item) {
			if (!item) return Toast('请输入企业/公司名称');
			this.$router.push({ name: 'assets', query: { item } });
			this.$nextTick(() => {
				this.list = [];
				this.onClear();
			});
		},
		getData(e = '') {
			if (!e) return (this.list = []);
			getComFuzzy({
				comFuzzy: e
			}).then(res => {
				this.list = res.data || [];
			});
		}
	}
};
</script>

<style scoped>
.content {
	width: 100%;
	height: calc(100vh);
	display: flex;
	flex-direction: column;
	background: #f5f5f5;
	overflow-x: hidden;
	overflow-y: scroll;
}
.top-search {
	height: 44px;
	position: fixed;
	z-index: 99;
	left: 0;
	right: 0;
	top: 46px;
	background: #ffffff;
	overflow: hidden;
}
.center-gray {
	position: absolute;
	left: 10px;
	right: 10px;
	height: 32px;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 16px;
	background: #f5f5f5;
	overflow: hidden;
}
.reletive {
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.btn-sure {
	width: 66px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	background: #006edc;
	border-radius: 14px;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 2px;
	opacity: 1;
	font-size: 13px;
}
.btn-sure:active {
	opacity: 0.8;
}
.search-icon {
	position: absolute;
	left: 7px;
	top: 4px;
	width: 24px;
	height: 24px;
}
.delet-box {
	position: absolute;
	right: 70px;
	top: 0;
	width: 20px;
	height: 20px;
	padding: 6px;
}
.input-box {
	position: absolute;
	left: 38px;
	top: 0;
	width: 100%;
	height: 32px;
	border: none;
	background: #f5f5f5;
	font-size: 14px;
	color: #000000;
	caret-color: #006edc;
}
.input-box:focus {
	font-size: 14px;
	color: ##000000;
}
.card-box {
	width: calc(100% - 20px);
	display: flex;
	flex-direction: column;
	background: #ffffff;
	margin-top: 106px;
	min-height: calc(100vh - 120px);
	margin-left: 50%;
	transform: translateX(-50%);
	border-radius: 8px;
	overflow: hidden;
	height: auto;
}
.cell {
	position: relative;
	height: 35px;
	min-height: 35px;
	max-height: 35px;
	width: 100%;
	overflow: hidden;
	line-height: 35px;
	text-indent: 15px;
	font-weight: 400;
	font-size: 13px;
	color: #000000;
}
.cell::after {
	content: '';
	height: 1px;
	width: calc(100% - 9px);
	left: 50%;
	transform: translateX(-50%);
	background: #d2d2d2;
	bottom: 0;
	position: absolute;
}
.empty {
	height: calc(100vh);
	width: 100%;
}
</style>
