<template>
	<div class="content">
		<NavBar :fixed="true" @click-left="onClickLeft" left-arrow title="商标管理"></NavBar>
		<!-- 商标信息 -->
		<div class="top-box">
			<div class="titile">商标信息提醒</div>
			<div class="card-box">
				<div v-for="(item, index) in brandList" :key="index" class="btn-box" @click.stop="onCompany(item, index)">
					<span class="t1" :class="{ blueI: index === 3 || index === 4 }">{{ item.num }}</span>
					<span class="t2">{{ item.title }}</span>
					<img @click.stop="onTip(index)" v-if="index === 3 || index === 4" class="ai" src="../../assets/ai.png" />
				</div>
			</div>
		</div>

		<!-- 侵权预警 -->
		<div class="card-qinquan">
			<div class="titile">侵权预警</div>
			<!-- num -->
			<div class="num-box">
				推断本期侵权商标数：
				<span class="blueI">{{ tort || 0 }}</span>
				个
			</div>
			<!--  -->
			<Button class="ButtonI" type="default" @click="onDetail" :round="true">查看详情</Button>
			<!-- line -->
			<div class="line"></div>
			<!--  -->
			<div class="text-box"><span>每期新公告的申请商标，我们会将之与您的注册商标比对，若存在近似，则有侵权风险，您可自行判断是否申请异议。</span></div>
		</div>
		<!-- 图 -->
		<div class="card-chart">
			<div v-show="chartData.length > 0" class="titile">商标注册类别统计</div>
			<chart :chartData="chartData" v-show="chartData.length > 0" class="charts-box"></chart>

			<!-- 来一组示例跟小程序保持一致 -->

			<div class="silei" v-show="chartData.length > 0">
				<div class="center-card">
					<div class="dot-box" v-for="(item, index) in chartData" :key="index">
						<div class="dot" :style="{ background: item.color }"></div>
						<span>{{ item.keys }}</span>
					</div>
				</div>
			</div>

			<van-empty v-show="chartData.length == 0" class="empty" image="search" description="暂无任何数据" />
		</div>
		<consult></consult>
	</div>
</template>

<script>
import { NavBar, Toast, Button } from 'vant';
import consult from '../../components/consult.vue';
import { getMonitorCompany, getUserTrademarkCount, getIntClsCount } from '@/api/api';
import chart from './piechart.vue';
const toast = msg => {
	Toast({
		message: msg,
		duration: 5000
	});
};
export default {
	components: {
		NavBar,
		consult,
		Button,
		chart
	},
	data() {
		return {
			brandList: [
				{
					num: 0,
					title: '商标总数'
				},
				{
					num: 0,
					title: '已注册商标'
				},
				{
					num: 0,
					title: '申请中商标'
				},
				{
					num: 0,
					title: '续展期商标'
				},
				{
					num: 0,
					title: '宽展商标'
				},
				{
					num: 0,
					title: '无效商标'
				}
			],
			// 侵权数
			tort: 0,
			com: '',
			chartData: []
		};
	},
	mounted() {
		this.com = this.$route.query.item;
		// this.com = '众泰控股集团有限公司';
		this.$nextTick(() => this.getData());
	},
	methods: {
		onCompany(item, index) {
			item.key = this.com;
			item.index = index;
			this.$router.push({ name: 'trademarkList', query: { ...item } });
		},

		onDetail() {
			this.$router.push({ name: 'tortToList', query: { com: this.com } });
		},

		onClickLeft() {
			this.$router.back();
		},

		onTip(index) {
			if (index === 4) toast('商标有十年有效期，如果没有续展，到期后半年，您还可以办理商标宽展，若未办理，则商标将被无效。');
			if (index === 3) toast('商标有十年有效期，如果要继续使用商标，在到期前半年，您可以办理商标续展，为商标“续费”。');
		},

		async getData(name = this.com) {
			try {
				Toast.loading({
					message: '加载中...',
					forbidClick: true
				});
				let res = [
					{
						...(await getUserTrademarkCount({
							comList: [name]
						})).data
					},
					[
						...(await getMonitorCompany({
							comName: [name]
						})).data
					],
					{
						...(await getIntClsCount({
							comName: name
						})).data
					}
				];

				try {
					this.brandList[0].num = res[0].trademarkAllNum || 0;
					this.brandList[1].num = res[0].regTrademarkALLNum || 0;
					this.brandList[2].num = res[0].applyTrademarkAllNum || 0;
					this.brandList[3].num = res[0].extensionTrademarkNum || 0;
					this.brandList[4].num = res[0].wideSpreadTrademarkNum || 0;
					this.brandList[5].num = res[0].loseEffcacyTrademarkNum || 0;

					this.$nextTick(() => {
						if (this.brandList.filter(x => x.num > 0).length === 0) Toast('暂无相关数据');
					});
				} catch (e) {
					//TODO handle the exception
				}

				try {
					this.tort = res[1].map(x => x.doc_count).reduce((a, b) => a + b);
				} catch (e) {
					//TODO handle the exception
					this.tort = 0;
				}

				let count_intCls = res[2].count_intCls.sort((a, b) => {
					return a.doc_count > b.doc_count;
				});

				let getCount = list => {
					return list.map(x => x.doc_count).reduce((a, b) => a + b);
				};

				let count = getCount(count_intCls);

				console.log(count);

				let max = 8;

				let getCutNum = list => {
					return [list.length > max ? max - 1 : list.length, list.length > max ? max - 1 : 0];
				};

				let arr = [
					...count_intCls.slice(0, getCutNum(count_intCls)[0]).map(x => {
						return (x = {
							keys: `${x.key} 类`,
							value: `${((x.doc_count / count) * 100).toFixed(2)}`,
							name: `${((x.doc_count / count) * 100).toFixed(2)}%`
						});
					})
				];

				if (count_intCls.length > max) {
					arr.push({
						keys: '其它',
						value: `${((getCount(count_intCls.slice(getCutNum(count_intCls)[1], count_intCls.length)) / count) * 100).toFixed(2)}`,
						name: `${((getCount(count_intCls.slice(getCutNum(count_intCls)[1], count_intCls.length)) / count) * 100).toFixed(2)}%`
					});
				}

				let color = ['#B2B2B2', '#FF5E00', '#00B853', '#800080', '#7C40FF', '#fc02ff', '#ff0000', '#0089FF'];

				this.chartData = arr.map((x, y) => {
					x.color = color[y];
					return x;
				});
			} catch (e) {
				//TODO handle the exception
				// Toast('企业名称输入不正确');
			}
		}
	}
};
</script>

<style scoped>
.content {
	background: #f5f5f5;
	min-height: calc(100vh);
	width: 100%;
	height: auto;
	overflow-x: hidden;
	overflow-y: scroll;
}

.top-box {
	width: calc(100% - 20px);
	height: 172px;
	position: relative;
	overflow: hidden;
	background: #ffffff;
	border-radius: 14px;
	overflow: hidden;
	margin-left: 50%;
	transform: translateX(-50%);
	margin-top: 56px;
}

.titile {
	position: absolute;
	top: 14px;
	left: 16px;
	color: #333333;
	font-size: 16px;
	font-weight: 500;
	z-index: 10;
}

.card-box {
	width: calc(100% - 20px);
	height: 126px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	top: 40px;
	overflow: hidden;
	z-index: 10;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	overflow: hidden;
}

.btn-box {
	width: 33.3333%;
	height: 50%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
}
.t1 {
	font-weight: bold;
	font-size: 24px;
}

.t2 {
	font-size: 12px;
	font-weight: 400;
	color: #000000;
	opacity: 0.6;
}
.ai {
	width: 20px;
	height: 20px;
	position: absolute;
	right: 0;
	top: 5px;
	z-index: 20;
	padding: 10px;
}
.card-qinquan {
	width: calc(100% - 20px);
	height: 180px;
	border-radius: 14px;
	overflow: hidden;
	background: #ffffff;
	margin-top: 10px;
	margin-left: 50%;
	transform: translateX(-50%);
	position: relative;
}
.line {
	height: 1px;
	background: #f5f5f5;
	width: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
}
.num-box {
	position: absolute;
	left: 16px;
	top: 52px;
	font-size: 13px;
	color: #333333;
}
.blueI {
	color: #006edc !important;
}
.ButtonI {
	position: absolute;
	width: 80px !important;
	height: 32px !important;
	position: absolute;
	top: 46px;
	right: 16px;
	font-size: 13px;
	padding: 0;
}
.text-box {
	position: absolute;
	overflow: hidden;
	width: calc(100% - 32px);
	bottom: 20px;
	overflow: hidden;
	height: 54px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 13px;
	color: #f96006;
	word-wrap: break-word;
}
.card-chart {
	width: calc(100% - 20px);
	min-height: 350px;
	height: auto;
	overflow: hidden;
	position: relative;
	margin-left: 50%;
	background: #ffffff;
	transform: translateX(-50%);
	margin-top: 10px;
	border-radius: 14px;
	margin-bottom: 100px;
}

.silei {
	position: absolute;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	bottom: 0;
	height: 90px;
	z-index: 40;
}

.center-card {
	position: relative;
	width: 80%;
	height: auto;
	margin: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
}

.dot {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin-right: 5px;
}

.dot-box {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 14px;
	width: auto;
	margin-right: 15px;
	overflow: hidden;
	margin-bottom: 5px;
	justify-content: flex-start;
}

.charts-box {
	margin: 0 130px 0 0;
	width: 100%;
	height: 260px;
	overflow: hidden;
	margin-top: 25px;
}
</style>
