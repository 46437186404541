<template>
	<div>
		<scrollView :lazyloading="true" :status="status" :top="46" :bottom="96" @onScroll="onScroll" @scroll-bottom="onMoreData">
			<div class="content">
				<NavBar :fixed="true" @click-left="onClickLeft" left-arrow title="商标信息"></NavBar>

				<!--  -->
				<div class="describe" v-if="list.length > 0">
					<div class="titile">您目前已有注册商标，如需帮助，请联系顾问。</div>
					<div class="num-box">
						<span>商标总数</span>
						<span style="color: #006EDC !important;">{{ toatl }}</span>
						<span>个</span>
					</div>
				</div>
				<!--  -->
				<div class="list-cell" v-for="(item, index) in list" :key="index">
					<img class="img" :src="'https://zhisheng-picture.oss-cn-hangzhou.aliyuncs.com/'+item.requestNum+'.jpg'" />
					<div class="span1 van-ellipsis">{{ item.trademarkName }}</div>
					<div class="span2">{{ item.trandemarkStatus }}</div>
					<div class="span3">
						<div class="left-span">类别</div>
						<div>{{ item.onecate }}类</div>
					</div>
					<div class="span4">
						<div class="left-span">注册号</div>
						<div>{{ item.requestNum }}</div>
					</div>
					<div class="span5">
						<div class="left-span">申请日期</div>
						<div>{{ item.app_date }}</div>
					</div>
				</div>

				<van-empty v-if="list.length == 0" class="empty" image="search" description="暂无任何数据" />
			</div>
		</scrollView>
		<!-- 滚动 -->
		<consult></consult>
	</div>
</template>

<script>
import { NavBar } from 'vant';
import { getTrademarkList } from '@/api/api';
import consult from '../../components/consult.vue';
import scrollView from '../../components/scrollView.vue';
export default {
	components: {
		NavBar,
		consult,
		scrollView
	},
	data() {
		return {
			list: [],
			companyData: {},
			page: 1,
			size: 10,
			toatl: 0,
			loading: false,
			finished: true,
			status: 'loadmore'
		};
	},
	mounted() {
		this.companyData = this.$route.query;
		this.$nextTick(() => this.getData());
	},
	methods: {
		onScroll() {},
		onMoreData() {
			// console.log('触底');
			if (this.status === 'loadmore') {
				this.status = 'loading';
				this.getData();
			}
		},
		onClickLeft() {
			this.$router.back();
		},
		getData() {
			try {
				let data = {
					comList: [this.companyData.key],
					index: this.page,
					size: this.size,
					type: this.companyData.index + 1
				};
				getTrademarkList(data).then(res => {
					try {
						this.toatl = res.data.total;
						let list = res.data.data || [];
						if (list.length >= this.size) (this.page += 1), (this.status = 'loadmore');
						if (list.length < this.size) this.status = 'nomore';
						this.list = this.list.concat(list);
					} catch (e) {
						//TODO handle the exception
						// Toast(e.message);
						this.status = 'loadmore';
					}
				});
			} catch (e) {
				//TODO handle the exception
				// Toast(e.message);
				this.status = 'loadmore';
			}
		}
	}
};
</script>

<style scoped>
.content {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-y: hidden;
}

.empty {
	height: calc(100vh);
	width: 100%;
}
.describe {
	width: 100%;
	height: 72px;
	min-height: 72px;
	overflow: hidden;
	position: relative;
	background: #ffffff;
	margin-top: 10px;
}
.titile {
	position: absolute;
	top: 16px;
	left: 16px;
	height: auto;
	width: calc(100% - 32px);
	font-size: 16px;
	font-weight: 500;
	color: #000000;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 22px;
	overflow: hidden;
}
.num-box {
	position: absolute;
	top: 50px;
	left: 16px;
	height: auto;
	width: calc(100% - 32px);
	font-size: 16px;
	font-weight: 500;
	color: #000000;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 22px;
	overflow: hidden;
}
.list-cell {
	width: calc(100%);
	height: 128px;
	min-height: 128px;
	max-height: 128px;
	background: #ffffff;
	position: relative;
	overflow: hidden;
	opacity: 1;
}
.list-cell:active {
	opacity: 0.8;
}
.list-cell::after {
	content: '';
	height: 1px;
	background: #f5f5f5;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}
.img {
	position: absolute;
	width: 56px;
	height: 56px;
	left: 16px;
	top: 16px;
}
.span1 {
	position: absolute;
	top: 16px;
	left: 84px;
	font-weight: 400;
	font-size: 15px;
	color: #000000;
	height: 20px;
	max-width: calc(100% - 84px - 84px);
	overflow: hidden;
}
.span2 {
	position: absolute;
	top: 17px;
	right: 16px;
	font-weight: 400;
	font-size: 13px;
	color: #006edc;
	height: 20px;
}
.span3 {
	position: absolute;
	top: 43px;
	left: 84px;
	font-weight: 400;
	font-size: 14px;
	color: #000000;
	opacity: 0.6;
	right: 0;
	width: 100%;
	height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.span4 {
	position: absolute;
	top: 67px;
	left: 84px;
	font-weight: 400;
	font-size: 14px;
	color: #000000;
	opacity: 0.6;
	right: 0;
	width: 100%;
	height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.span5 {
	position: absolute;
	bottom: 17px;
	left: 84px;
	font-weight: 400;
	font-size: 14px;
	color: #000000;
	opacity: 0.6;
	right: 0;
	width: 100%;
	height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.left-span {
	width: 60px;
	min-width: 60px;
	max-width: 60px;
	margin-right: 20px;
	overflow: hidden;
}
</style>
